import React from 'react';
import CreateBemClasses from '~/hooks/CreateBemClasses.js';

import './Contact.scss';

/*
	CONTACT: Single Contact Entry
*/
const Contact = (props) => {
	const {
		protocol,
		url,
		modifiers,
		mix,
		children
	} = props;

	return (
		<a
			href={ (protocol ? protocol : null ) + url }
			className={CreateBemClasses('contact', modifiers, mix)}
		>
			{ children ? children : url}
		</a>
	);
};

export default Contact;