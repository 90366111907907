import React, { useState, useEffect } from 'react';
import useScrollPosition from '~/hooks/useScrollPosition.js';

import SkipLink from '~/blocks/SkipLink/SkipLink.js';
import Logo from '~/blocks/Logo/Logo.js';
import Menu from '~/blocks/Menu/Menu.js';
import FakeCrumb from '~/blocks/FakeCrumb/FakeCrumb.js';

import '~/helper-blocks/Utilities/Utilities.scss';
import './Header.scss';

/*
	HEADER: site header
*/
const Header = ({title}) => {
	const scrollTop = useScrollPosition();
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		if (scrollTop > 100) {
			setIsScrolled(true);
		} else {
			setIsScrolled(false);			
		}
	}, [scrollTop]);

	return (
		<header
			className={'header page__header' + (isScrolled ? ' header--is-scrolled' : '')}
			role="banner"
		>
			<div className="header__centered centered">
				<SkipLink />

				<div className="grid grid--gap--1 grid--2-columns grid--4-columns-md-up">
					<Logo />
					<Menu />
				</div>
			</div>

			<div className="header__fake-crumb">
				<div className="header__fake-crumb-centered centered">
					<FakeCrumb>{ title }</FakeCrumb>
				</div>
			</div>
		</header>
	);
};

export default Header;