import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import './Logo.scss';

/*
	LOGO: the site's main logo
	Changes from an h1 to a strong tag when not at the home page
*/
const Logo = () => {
	const data = useStaticQuery(graphql`
		query LogoQuery {
			site {
				siteMetadata {
					title
					logo
				}
			}
		}
	`);

	let location = useLocation();

	const LogoTag = (location.pathname === '/') ? 'h1' : 'strong';
	const {
		title,
		logo,
	} = data.site.siteMetadata;

	return (
		<Link
			to="/"
			title="" 
			className="logo"
			accessKey="h"
		>
			<LogoTag className="logo__heading">
				<img
					src={ logo }
					alt={ title }
					title={ title }
					className="logo__image"
				/>
			</LogoTag>
		</Link>
	)
};

export default Logo;
