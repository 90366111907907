import React from 'react';

import CreateBemClasses from '~/hooks/CreateBemClasses.js';

import MenuItem from '~/blocks/MenuItem/MenuItem.js';

/*
	SUB MENU: the site's menu submenu.
*/
const SubMenu = (props) => {
	const {
		className,
		modifiers, // BEM modifier array
		mix, // BEM mix classes
		routes // Routes received from the parent <MenuItem>
	} = props;

	if ( modifiers && className ) {
		var classString = CreateBemClasses(className, modifiers);
	} else {
		classString = className;
	}

	return (
		<ul
			{...props}
			className={
				( className || mix) ?
					`${classString ? classString : ''}
					${mix ? mix : ''}`
				: null
			}
			role="menu"
		>
			{
				routes.map( route => {
					if (!route.showInMenu) {
						return false;
					}
					return (
						<MenuItem
							key={route.path}
							route={route}
						/>
					)
				})
			}
		</ul>
	)
};

export default SubMenu;