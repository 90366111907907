import React from 'react';

import './SkipLink.scss';

/*
	SKIP LINK: a "skip to content" link. Skips to the <Main> component
*/
const SkipLink = () => (
	<a
		href="#main"
		title="Pular navegação"
		accessKey="s"
		className="skip-link"
		tabIndex="1"
	>
		Pular Navegação (s)
	</a>
);

export default SkipLink;