// Stores and updates the window's dimensions
import { useState, useEffect } from 'react';

function getScrollPosition(windowRef) {
	var scrollTop = (windowRef.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);

	return scrollTop;
}

export default function useScrollPosition() {
	const [scrollPosition, setScrollPosition] = useState(0);

	useEffect(() => {
		setScrollPosition(getScrollPosition(window));
		
		function handleScroll() {
			setScrollPosition(getScrollPosition(window));
		}

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return scrollPosition;
}