// Creates a string of classes based on the base class and the modifiers
function CreateBemClasses (className, modifiers, mix) {
	var modifierString = className;
	
	if ( typeof modifiers !== 'undefined' ) {
		
		if ( Array.isArray(modifiers) ) {
			modifierString += ' ';

			modifiers.map( (modifier, index, array) => {
				return modifierString += className + '--' + modifier + (array.length - 1 !== index ? ' ' : '');
			});
		}

		else {
			modifierString += ' ' + className + '--' + modifiers
		}
	}

	if ( mix ) {
		modifierString += ' ' + mix
	}

	return modifierString;
}

export default CreateBemClasses;