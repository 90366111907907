import React, { useState, useEffect } from 'react';
import useWindowDimensions from '~/hooks/useWindowDimensions.js';
import { useStaticQuery, graphql } from "gatsby"

import Button from '~/blocks/Button/Button.js';
import MenuItem from '~/blocks/MenuItem/MenuItem.js';
import Icon from '~/blocks/Icon/Icon.js';

import './Menu.scss';
import '~/blocks/MenuToggle/MenuToggle.scss';

/*
	MENU: the site's main menu.
	Builds a nav list based on the `routes` set at gatsby-config.js
*/
const Menu = () => {
	const data = useStaticQuery(graphql`
		query SiteMenuQuery {
			site {
				siteMetadata {
					routes {
						anchorText
						path
						showInMenu
						hideInTopMenu
						external
						openInNewTab
					}
					breakpoints {
						lg
						md
						sm
						xl
						xs
					}
				}
			}
		}
	`);

	const {
		routes,
		breakpoints
	} = data.site.siteMetadata;

	const { width } = useWindowDimensions();
	let initialIsOpened = (width > breakpoints.md) ? true : false;
	const [isOpened, setIsOpened] = useState(initialIsOpened);
	
	// When the window changes, the initial opened can change.
	// If it does, sets the opened state to the new initial state.
	useEffect(() => {
		if (initialIsOpened !== isOpened) {
			setIsOpened(initialIsOpened);
		}
	}, [initialIsOpened]);

	return (
		<>
			<Button
				className="menu-toggle"
				mix="hidden-md-up"
				onClick={ () => setIsOpened(!isOpened)}
				aria-expanded={isOpened}
				aria-controls="menu"
				aria-label="Menu"
			>
				<Icon
					symbol="menu"
					mix="menu-toggle__icon"
				/>
			</Button>

			<nav
				className="menu grid__item--full-width grid__item--col-span--3-md-up"
				role="navigation"
				aria-label="Menu Principal"
				aria-hidden={!isOpened}
				id="menu"
			>
				<ul
					className="menu__level-1"
					role="menu"
				>
					{
						routes.map( route => {
							if (!route.showInMenu) {
								return false;
							}
							if (route.hideInTopMenu) {
								return false;
							}
							return (
								<MenuItem
									key={route.path}
									route={route}
									icon={true}
								/>
							)
						})
					}
				</ul>
			</nav>
		</>
	)
};

export default Menu;