import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

import FooterMenu from '~/blocks/FooterMenu/FooterMenu.js';
import Contact from '~/blocks/Contact/Contact.js';
import Social from '~/blocks/Social/Social.js';
import Heading from '~/blocks/Heading/Heading.js';
import Authorship from '~/blocks/Authorship/Authorship.js';

import '~/helper-blocks/Utilities/Utilities.scss';
import './Footer.scss';

/*
	FOOTER: site footer / colophon
*/
const Footer = (props) => {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			site {
				siteMetadata {
					title
					phoneNumbers
				}
			}
		}
	`);

	const {
		title,
		phoneNumbers
	} = data.site.siteMetadata;

	return (
		<footer
			id="footer"
			className="footer page__footer"
		>
			<div className="footer__centered centered grid grid--3-columns-md-up grid--gap--1 grid--gap--2-md-up">
				<aside className="footer__contacts grid__item grid grid--gap--1">
					<div className="grid__item">
						<Heading level={3} className="footer__title">
							Assessoria de Imprensa
						</Heading>

						<Heading className="footer__sub-title">
							Capital Informação
						</Heading>
					</div>

					<Contact
						url="ricardo@capitalinformacao.com.br"
						protocol="mailto:"
					>
						Ricardo Varoli - ricardo@capitalinformacao.com.br
					</Contact>

					<Contact
						url="adriana@capitalinformacao.com.br"
						protocol="mailto:"
					>
						Adriana Athayde - adriana@capitalinformacao.com.br
					</Contact>

					<Contact
						url="luciane@capitalinformacao.com.br"
						protocol="mailto:"
					>
						Luciane Bernardi - luciane@capitalinformacao.com.br
					</Contact>
				</aside>

				<div className="footer__menu grid__item grid grid--gap--1">
					<aside className="grid__item">
						<Heading level={3} className="footer__title">
							Central de Relacionamento
						</Heading>

						{
							phoneNumbers.map( number => {
								return (
									<Contact
										key={number}
										protocol="tel:"
										url={number}
									/>
								)
							})
						}
					</aside>

					<FooterMenu />
				</div>

				<aside className="footer__social grid__item">
					<Social level={3} />

					<address className="footer__copyright">
						{ `${title} - ${new Date().getFullYear()} - Todos os direitos reservados` }
					</address>
				</aside>
			</div>
			
			<div className="centered">
				<div className="footer__signature">
					<Authorship
						author="Marcas com Sal"
						logo="/images/logotipo-sal-escuro.png"
						website="https://marcascomsal.com/"
						role="Criação"
					/>

					<Authorship
						author="Strongway Webstudio"
						logo="/images/logotipo-strongway-escuro.svg"
						website="https://strongway.com.br/"
						role="Desenvolvimento"
					/>
				</div>
			</div>
		</footer>
	)
};

export default Footer;