import React from 'react';

import './Authorship.scss';

/*
	AUTHORSHIP: show an author signature
*/
const Authorship = (props) => {
	const {
		author, // Author/Company Name
		logo, // Logo path
		website, // Author URL
		role
	} = props;

	return (
		<a
			className="authorship"
			href={website}
			title={author}
			target="_blank"
			rel="noreferrer noopener"
		>
			{
				role ?
				<span className="authorship__role">{ role }</span>
				: null
			}
			<img
				className="authorship__logo"
				src={logo}
				alt={author}
			/>
		</a>
	);
};

export default Authorship;