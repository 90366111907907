import React from 'react';

import CreateBemClasses from '~/hooks/CreateBemClasses.js';

import './Icon.scss';

/*
	ICON: SVG Symbol Icon
*/
const Icon = (props) => {
	const {
		symbol,
		mix
	} = props;

	return (
		<svg
			{...props}
			className={CreateBemClasses('icon', symbol, mix)}
		>
			<use xlinkHref={'/symbols/symbol-defs.svg#icon--' + symbol}></use>
		</svg>
	)
};

export default Icon;