import React from "react"
import { graphql } from "gatsby"

import Page from "~/blocks/Page/Page.js"
import SEO from "~/blocks/Seo/Seo.js"

import "~/helper-blocks/Content/Content.scss"

/*
	ABOUT PAGE: @todo: talvez nem exista
*/
const AboutPage = ({data}) => {
	return (
		<Page
			title="Sobre a CantuStore"
			centered="true"
		>
			<SEO
				title="Sobre a CantuStore"
				description="Onde alguns veem pneus, a gente enxerga caminhos. Conheça a CantuStore."
				pathname="/"
			/>

			<div className="grid grid--gap--1">
				<div className="content">
					<h1>Sobre nós</h1>

					<p>Somos a <strong>CantuStore</strong>: Plataforma de tecnologia e logística que viabiliza soluções completas em pneus, guiando quem compra e apoiando quem vende. Se o assunto é pneu, você resolve aqui. Produtos e serviços em uma experiência 360° para abrir caminhos e ver pessoas e negócios evoluindo junto com a gente. Afinal, <em>ficar parado não é opção</em>, pelos menos pra nós.</p>

					<p>Nosso time adora realizar projetos. Planejamos, executamos, corrigimos e mandamos ver com uma velocidade incrível. O nosso compromisso é estar antenado ao que está por vir e antecipar o futuro para oferecer a melhor solução para clientes, parceiros e colaboradores.</p>

					<p>Nascemos para os inquietos, para aqueles que têm sede por mudanças e estão sempre em movimento. Ultrapassamos barreiras rumo ao infinito de possibilidades.</p>

					<p><em><strong>CantuStore</strong>. Abrindo caminhos para pessoas e negócios.</em></p>
				</div>
			</div>
		</Page>
	)
}

export default AboutPage;

export const query = graphql`
	{
		aboutFeaturedImage: file(
			relativePath: {
				eq: "home-banners/home-banner-1.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1006) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`