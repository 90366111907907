import React from 'react';

import './Heading.scss';

/*
	HEADINGS: content headings, with settable heading level
*/
const Heading = (props) => {
	const {
		level, // HTML heading level 1-6
		className, // Class from .h1 to .h6 to override level styling (and also any other mix classes)
		children,
	} = props;

	// If a level is not provided, it is considered a `strong`` tag
	const HeadingTag = (level ? `h${level}` : 'strong');

	return (
		<HeadingTag
			level={null} // Overrides the prop in order to hide it from the DOM
			className={className}
		>
			{children}
		</HeadingTag>
	);
};

export default Heading;