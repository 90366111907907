import React from 'react';

import CreateBemClasses from '~/hooks/CreateBemClasses.js';

import './Button.scss';

/*
	BUTTON: multi purpose general button
*/
const Button = (props) => {
	const {
		className,
		modifiers, // BEM modifiers array
		mix, // BEM mix classes
		children,
	} = props;

	if ( modifiers && className ) {
		var classString = CreateBemClasses(className, modifiers);
	} else {
		classString = className;
	}

	return (
		<button
			{...props}
			className={
				( className || mix) ?
					`${classString ? classString : ''}
					${mix ? mix : ''}`
				: null
			}
		>
			{children}
		</button>
	)
};

export default Button;