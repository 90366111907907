import React from 'react';

import './FakeCrumb.scss';

/*
	FAKE CRUMB: Looks like a breadcrumb, but it's not
*/
const FakeCrumb = ({children}) => {
	return (
		<div
			className="fake-crumb"
			aria-hidden="true"
		>
			{ children }
		</div>
	);
};

export default FakeCrumb;