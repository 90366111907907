import React, { useState } from 'react';
import { Link } from 'gatsby';

import Button from '~/blocks/Button/Button.js';
import SubMenu from '~/blocks/SubMenu/SubMenu.js';
import Icon from '~/blocks/Icon/Icon.js';

import './MenuItem.scss';

/*
	MENU ITEM: a single menu item with children based on the routes provided as props
*/
const MenuItem = (props) => {
	const { route, icon, mix } = props;
	const subMenuId = `submenu${route.path.replace(/\\|\/|:|\?|&|\[|\]/g, '-')}`
	const [isOpened, setIsOpened] = useState(false);

	let ItemTag = Link;
	let itemProps = { to: route.path };

	if ( route.external ) {
		ItemTag = 'a';
		itemProps = { href: route.path };
	}

	if ( route.openInNewTab ) {
		itemProps.target = '_blank';
		itemProps.rel = 'noreferrer noopener'
	}

	return (
		<li
			className={`menu-item ${ mix ? mix : '' }`}
			role="menuitem"
			aria-haspopup="menu"
		>	
			<ItemTag
				{...itemProps}
				className={'menu-item__link' + ( route.highlight ? ' button' : '')}
			>
				{route.anchorText}
				{
					icon ? 
					<Icon
						symbol="chevron-right"
						mix="menu-item__icon"
					/>
					: null
				}
			</ItemTag>

			{
				// If the route has child routes, it builds a <SubMenu>
				(route.childRoutes) ?
				<>
					<Button
						className="menu-item__toggle"
						onClick={ () => setIsOpened(!isOpened)}
						aria-expanded={isOpened}
						aria-controls={subMenuId}
					>Abrir</Button>
					<SubMenu
						id={subMenuId}
						className="menu-item__submenu"
						mix="menu__level-2" // @todo: recursive incremental levels
						routes={route.childRoutes}
						aria-hidden={!isOpened}
					/>
				</>
				: null
			}
		</li>
	)
};

export default MenuItem;