import React from 'react';
import ScrollToTop from '~/hooks/ScrollToTop.js';
import { Helmet } from "react-helmet"

import Header from '~/blocks/Header/Header.js';
import Main from '~/blocks/Main/Main.js';
import Footer from '~/blocks/Footer/Footer.js';

import '~/scss/_core.scss';
import '~/scss/_misc.scss';
import './Page.scss';

/*
	PAGE: the basic page skeleton, reloaded with react-hot-loader.
	It passes it's children as children for the <Main> component
*/
const Page = ({title, centered, stuckTop, children}) => (
	<>
		<ScrollToTop />
		<Helmet>
			<html className="page" lang="pt-br" />
		</Helmet>
		<Header title={title} />
		<Main
			centered={centered}
			stuckTop={stuckTop}
		>
			{children}
		</Main>
		<Footer />
	</>
);

export default Page;