import React from 'react';

import '~/helper-blocks/Utilities/Utilities.scss';
import './Main.scss';

/*
	MAIN: the site's main content tag.
	It is filled with the page's content based on the Route
*/
const Main = ({centered, stuckTop, children}) => (
	<main
		id="main"
		className={
			'main page__main'
			+ (centered ? ' main--centered' : '')
			+ (stuckTop ? ' main--stuck-top' : '')
		}
		tabIndex="-1"
		role="main"
	>
		{
			centered ?
			<div className="main__centered centered">
				{children}
			</div>
			: <>{children}</>
		}
	</main>
);

export default Main;