import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

import MenuItem from '~/blocks/MenuItem/MenuItem.js';

import './FooterMenu.scss';

/*
	FOOTER MENU: the site's footer menu.
	Builds a nav list based on the `routes` set at gatsby-config.js
*/
const Menu = () => {
	const data = useStaticQuery(graphql`
		query FooterMenuQuery {
			site {
				siteMetadata {
					routes {
						anchorText
						path
						showInMenu
						hideInTopMenu
						external
						openInNewTab
					}
				}
			}
		}
	`);

	const {
		routes,
	} = data.site.siteMetadata;

	return (
		<nav
			className="footer-menu"
			role="navigation"
			aria-label="Menu de Rodapé"
			id="menu"
		>
			<ul
				className="footer-menu__level-1"
				role="menu"
			>
				{
					routes.map( route => {
						if (!route.showInMenu) {
							return false;
						}
						return (
							<MenuItem
								key={route.path}
								route={route}
								mix="footer-menu__menu-item"
							/>
						)
					})
				}
			</ul>
		</nav>
	)
};

export default Menu;