import React from 'react';
import CreateBemClasses from '~/hooks/CreateBemClasses.js';
import { useStaticQuery, graphql } from "gatsby"

import Icon from '~/blocks/Icon/Icon.js';
import Heading from '~/blocks/Heading/Heading.js';

import './Social.scss';

/*
	SOCIAL: Social Icons
*/
const Social = (props) => {
	const data = useStaticQuery(graphql`
		query SocialQuery {
			site {
				siteMetadata {
					social {
						name
						symbol
						url
					}
				}
			}
		}
	`);

	const { social } = data.site.siteMetadata;

	const {
		level,
		modifiers,
		mix
	} = props;

	return (
		<div className={CreateBemClasses('social', modifiers, mix)}>
			<Heading
				level={level}
				className="social__title"
			>
				Siga nossas redes
			</Heading>

			<ul	className="social__list">
				{
					social.map( social => {
						return (
							<li key={social.symbol} className="social__item">
								<a
									href={social.url}
									target="_blank"
									rel="noreferrer noopener"
									aria-label={social.name}
									className="social__link"
								>
									<Icon
										symbol={social.symbol}
										mix="social__icon"
									/>
								</a>
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};

export default Social;